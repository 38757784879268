.title_perche_noi {
  width: 500px;
  height: 50px;
  margin: 80px auto 50px auto;
  text-align: center;
  font-family: "Glegoo", sans-serif;
  font-size: 35px;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .title_perche_noi {
    width: 350px;
    font-size: 25px;
  }
}

@media screen and (max-width: 630px) {
  .title_perche_noi {
    width: 300px;

    font-size: 20px;
  }
}

.my_video {
  display: table;
  margin: 0 auto;
}

.my_video_inside {
  width: 640px;
  height: 400px;
}

@media screen and (max-width: 900px) {
  .my_video_inside {
    width: 540px;
    height: 338px;
  }
}

@media screen and (max-width: 630px) {
  .my_video_inside {
    width: 400px;
    height: 250px;
  }
}

@media screen and (max-width: 450px) {
  .my_video_inside {
    width: 300px;
    height: 188px;
  }
}
