.main_holder_team {
  max-width: 999px;
  margin: 0 auto;
  padding: 36px 20px 47px 20px;
  display: block;
}

.team-block {
  padding: 0 0 72px;
}
