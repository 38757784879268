.intro {
  border-top: 2px solid #8f8d87;
  border-bottom: 2px solid #8f8d87;
  display: block;
  color: #4a4b4c;
  height: 100%;
  font: 19px/28px "AdobeCaslonW01-Regular", Times, serif;
}

.intro h1 {
  display: inline-block;
  vertical-align: middle;
  margin: 0 11% 0 0;
  width: 38.5%;
  text-transform: uppercase;
  font-size: 45px;
  font-family: "Helvetica";
  font-weight: 550;
  margin-block-start: 0.43em;
  margin-block-end: 0.43em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #4a4b4c;
  line-height: 55px;
}

.intro .box {
  display: inline-block;
  vertical-align: middle;
  width: 61.5%;
  font-size: 26px;
  line-height: 28px;
}

.intro p {
  margin: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: left;
  padding: 3% 3% 0% 15%;
  font-size: 25px;
}

@media screen and (max-width: 1000px) {
  .intro h1 {
    font-size: 40px;
  }

  .intro p {
    font-size: 22px;
    margin-block-end: -5px;
  }
}

@media screen and (max-width: 930px) {
  .intro h1 {
    font-size: 37px;
  }

  .intro p {
    font-size: 20px;
    margin-block-end: -5px;
  }
}

@media screen and (max-width: 820px) {
  .intro h1 {
    font-size: 35px;
    width: 100%;
  }

  .intro p {
    position: relative;
    font-size: 22px;
    margin-block-end: 0px;
    width: 90vw;
    padding: 0;
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 600px) {
  .intro h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .intro p {
    font-size: 18px;
    line-height: 25px;
  }
}
