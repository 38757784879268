.full_rectangle {
  height: 450px;
  width: 100%;
  margin: 0 0 30px 0;
}

.half_left {
  width: 60%;
  height: 100%;
  float: left;
  padding: 3% 3% 3% 20%;
}

@media screen and (max-width: 700px) {
  .half_left {
    width: 100%;
    padding: 3% 3% 3% 15%;
  }
}

.half_right_3 {
  width: 40%;
  height: 100%;
  margin-left: 60%;
}

.image_half_3 {
  width: auto;
  height: 90%;
  display: block;
  margin: 0 auto 0 10%;
  padding-top: 8%;
}

.h3_rectangle_2_4 {
  font-family: "Glegoo", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding-left: 10px;
}

.list_v #second_ul {
  list-style-image: url("../../../photos/icon_check_2.png");
  font-size: 20px;
}

.list_v ul li {
  line-height: 30px;
  margin: 30px 0;
  padding-left: 10px;
  font-size: 22px;
}

@media screen and (max-width: 1160px) {
  .half_left {
    padding: 3% 3% 3% 13%;
  }

  .image_half_3 {
    width: auto;
    height: 80%;
    padding-top: 12%;
  }

  .h3_rectangle_2_4 {
    font-size: 23px;
    font-weight: 600;
  }

  .list_v ul li {
    line-height: 25px;
    margin: 25px 0;
    padding-left: 10px;
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .image_half_3 {
    padding-left: 0%;
    margin: 0;
  }
}

@media screen and (max-width: 780px) {
  .image_half_3 {
    height: 76%;
    padding-top: 20%;
  }
}

@media screen and (max-width: 630px) {
  .h3_rectangle_2_4 {
    font-size: 20px;
    margin-left: 18px;
  }

  .list_v ul li {
    font-size: 16px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 350px) {
  .h3_rectangle_2_4 {
    margin-left: 0px;
  }
}
