.logo_footer {
  width: 40px;
  height: 40px;
}

.p_footer {
  margin: 5px 0 5px 0;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .p_footer {
    margin: 20px 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .p_footer {
    margin: 20px 0 0 0;
  }
}
