@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.responsive {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background: url(/static/media/homepage_photo.bed2f0b5.jpg) no-repeat center top;
}

.main_logo {
  -webkit-animation: fadein 6s;
  animation: fadein 6s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main_phrase {
  color: white;
  -webkit-animation: fadeinT 2s;
  animation: fadeinT 2s;
}

@media screen and (max-height: 690px) {
  .main_phrase {
    color: #ffffff;
    opacity: 0;
    -webkit-animation: fadeinT 0s;
    animation: fadeinT 0s;
  }
}

@keyframes fadeinT {
  0% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-0px);
            transform: translateX(-0px);
    opacity: 1;
  }
}

@-webkit-keyframes fadeinT {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
* {
  margin: 0;
  padding: 0;
}
/* html {
  scroll-behavior: smooth;
} */
html,
body {
  height: 100%;
}
section {
  position: relative;
  width: 100%;
  height: 100%;
}
section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 80%;
}
section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font: normal 300 64px/1 "Josefin Sans", sans-serif;
  text-align: center;
  white-space: nowrap;
}

.scroll_homepage a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}
.scroll_homepage a:hover {
  opacity: 0.5;
}

#section05 a {
  padding-top: 70px;
}
#section05 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

#main {
  padding: 50px 50px 50px 50px !important;
  position: relative;
  z-index: 5;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  display: block;
}

.box_left {
  width: 40%;
  margin-left: 40px;
  display: inline-block;
  box-sizing: border-box;
}

.box_left {
  height: 300px;
  background: #00acec;
  padding: 40px;
  z-index: 10;
  position: relative;
}

.box_left h2 {
  color: #fff;
  line-height: 1.1em;
  font-size: 46px;
  font-family: "Glegoo", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.next_to_box {
  padding-top: 40px;
  padding-left: 5%;
  display: inline-block;
  width: 55%;
  vertical-align: top;
  box-sizing: border-box;
}

.next_to_box h4 {
  font-size: 26px;
  line-height: 33.8px;
  clear: both;
  font-weight: 100;
  color: #404040;
  margin: 0 0 26px 0;
  font-family: "Times New Roman";
}

.image_business {
  width: 100%;
  height: 300px;
  background: url(/static/media/foto_1.9e347501.jpg);
  background-size: cover;
  background-position: 50% 50%;
  margin-top: -100px;
  position: relative;
  z-index: -1;
}

#content_3:before {
  content: "Al centro delle tue esigenze";
  font-family: "Times New Roman";
  font-weight: 700;
  font-size: 32px;
}

@media screen and (max-width: 1190px) {
  .box_left {
    width: 100%;
    height: auto;
    margin-left: 0;
    background: none;
    padding: 0;
    display: inline-block;
    box-sizing: border-box;
    z-index: 10;
    position: relative;
    text-align: center;
  }

  .box_left h2 {
    margin: 0 auto 0 auto;
    color: #00acec;
    padding: 0;
    max-width: 500px;
    font-size: 54px;
    line-height: 1em;
    font-family: "Glegoo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    clear: both;
  }

  .next_to_box {
    width: 100%;
    padding: 0;
    vertical-align: top;
    box-sizing: border-box;
    text-align: center;
  }

  .next_to_box h4 {
    font-size: 24px;
    padding: 0 10%;
    line-height: 1.3em;
    margin: 30px 0 30px 0;
  }

  .image_business {
    margin: 0 0 0 0;
    height: 270px;
  }

  #content_3:before {
    content: none;
  }
}

@media screen and (max-width: 630px) {
  .box_left h2 {
    margin: 0px auto 0 auto;
    color: #00acec;
    padding: 0;
    max-width: 500px;
    font-size: 35px;
    line-height: 1em;
    font-family: "Glegoo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    clear: both;
  }

  .next_to_box h4 {
    font-size: 20px;
  }

  .half h5 {
    font-size: 21px !important;
  }

  #main {
    padding: 50px 40px 50px 40px !important;
  }

  .text_h2 {
    margin-top: 80px;
  }
}

.services_container {
  overflow: hidden;
  padding-top: 40px;
  margin-top: -40px;
}

.half {
  width: 50%;
  float: left;
  padding: 0 20px 0 80px;
  box-sizing: border-box;
  margin-top: -20px;
}

.half h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.1em;
  font-size: 40px;
  font-family: "Glegoo", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.half h5 {
  font-size: 25px;
  line-height: 28px;
  clear: both;
  font-weight: 300;
  color: #404040;
  margin: 0 0 26px 0;
  font-family: "Times New Roman";
}

.half p {
  font-size: 20px;
  line-height: 25px;
  clear: both;
  font-weight: 100;
  color: #404040;
  margin: 0 0 26px 0;
  font-family: "Times New Roman";
}

@media screen and (max-width: 900px) {
  .half {
    width: 100%;
    float: bottom;
    padding: 0 0px 0 0px;
    margin: 0 0 0 0;
  }

  .icon_circle {
    opacity: 0;
  }

  .icon_circle i {
    display: none;
  }

  .elegant_icon {
    display: none;
  }

  .half h2 {
    font-size: 35px;
    text-align: center;
  }

  .half h5 {
    text-align: center;
  }

  .half p {
    text-align: center;
  }
}

@media screen and (max-width: 335px) {
  .box_left h2 {
    font-size: 25px;
  }

  .half h2 {
    font-size: 30px;
    text-align: center;
  }
}

.icon_circle {
  background: #00acec;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  top: -10px;
  left: -40px;
  text-align: center;
  display: table;
  box-shadow: 0 0 0 2px #fff, 0 0 0 7px #00acec;
}

.icon_circle i {
  color: #fff;
  font-size: 35px;
  display: table-cell;
  vertical-align: middle;
}

.elegant_icon {
  font-family: "ElegantIcons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

#content_1:before {
  content: "G&F CONSULTING STP SRL nasce nel 2018 dall'esperienza pluridecennale dei suoi collaboratori e vanta ad oggi pi\F9   di 80 aziende in tutta Italia. La lunga esperienza maturata garantisce ai nostri clienti una competenza e conoscenza approfondita della materia.";
}

#content_2:before {
  content: "Perch\E8   scegliere noi? Ci impegniamo ogni giorno per ascoltare le vere esigenze dei nostri clienti e trovare una risposta efficace ai loro problemi. Da sempre investiamo nell'innovazione per offrirti una qualit\E0   elevata e velocit\E0   nelle risposte.";
}

@media screen and (max-width: 630px) {
  #content_1:before {
    content: none;
  }

  #content_2:before {
    content: none;
  }

  .home_testimonial {
    padding: 15px 15px 15px 15px !important;
    margin: 50px 10px 50px 10px !important;
  }

  .home_testimonial h4 {
    font-size: 20px;
  }
}

.home_testimonial {
  background: #eee;
  border-radius: 30px;
  text-align: center;
  margin: 50px 40px 76px 40px;
  padding: 30px 60px;
  border: 2px solid #eee;
  border-left: 0;
  border-right: 0;
  position: relative;
  display: block;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

#myPageContent,
section {
  height: 100% !important;
  width: 100% !important;
}

#textSlider.row {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100% !important;
}
#textSlider div {
  color: black;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}

.iamCol {
  text-align: right;
  float: left;
  font-weight: 300;
  font-size: 30pt;
}
.iamCol p:nth-child(2) {
  margin-top: -20pt !important;
}
.slideCol {
  text-align: left;
  overflow: hidden;
  font-weight: 900;
  font-size: 70pt;
  display: block;
  white-space: nowrap;
}
.slideCol p {
  margin: 0px !important;
}

.scroller {
  height: 70pt;
  line-height: 70pt;
  overflow: hidden;
}
.scroller .inner {
  -webkit-animation: 10s normal infinite running scroll;
          animation: 10s normal infinite running scroll;
}
@-webkit-keyframes scroll {
  0% {
    margin-top: 0px;
  }
  15% {
    margin-top: 0px;
  }

  25% {
    margin-top: -70pt;
  }
  40% {
    margin-top: -70pt;
  }

  50% {
    margin-top: -140pt;
  }
  65% {
    margin-top: -140pt;
  }

  75% {
    margin-top: -210pt;
  }
  90% {
    margin-top: -210pt;
  }

  100% {
    margin-top: 0px;
  }
}
@keyframes scroll {
  0% {
    margin-top: 0px;
  }
  15% {
    margin-top: 0px;
  }

  25% {
    margin-top: -70pt;
  }
  40% {
    margin-top: -70pt;
  }

  50% {
    margin-top: -140pt;
  }
  65% {
    margin-top: -140pt;
  }

  75% {
    margin-top: -210pt;
  }
  90% {
    margin-top: -210pt;
  }

  100% {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .iamCol {
    text-align: center;
    font-size: 20pt;
  }
  .iamCol p {
    display: inline !important;
  }
  .slideCol {
    font-size: 25pt;
    text-align: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 765px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .iamCol {
    text-align: center;
    font-size: 25pt;
  }
  .iamCol p {
    display: inline !important;
  }
  .slideCol {
    font-size: 38pt;
    text-align: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1192px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .iamCol {
    text-align: right;
    font-size: 30pt;
  }
  .slideCol {
    font-size: 55pt;
    text-align: left;
  }
}

.image_business2 {
  width: 100%;
  height: 300px;
  background: url(/static/media/foto_4.4ec2acf5.jpg);
  background-size: cover;
  background-position: 50% 50%;
  margin-top: -50px;
  position: relative;
  z-index: -1;
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  transition-duration: 0.4s;
  cursor: pointer;
  margin: 70px auto;
}

.button2 {
  background-color: white;
  color: #008cba;
  border: 2px solid #008cba;
}

.button2:hover {
  background-color: #008cba;
  color: white;
}

.centerButton {
  margin: auto auto;
  width: 160px;
}

.homepageh2 {
  margin-top: -10px;
}

.team_page {
  position: relative;
  width: 100%;
  height: 75%;
  min-height: 75vh;
  top: 0;
  left: 0;
  background-image: url(/static/media/Team.76c50d44.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.team_h1 {
  position: relative;
  color: white;
  top: 345px;
  min-height: 130px;
  font-family: "Glegoo", sans-serif;
  font-size: 40px;
  font-weight: 550;
  margin: 0px 90px 0px 90px;
}

.team_h2 {
  position: relative;
  color: white;
  font-family: "Times New Roman";
  font-size: 30px;
  font-weight: 500;
  top: 270px;
  margin: 0px 90px 0px 90px;
  -webkit-animation: fadeinteam 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeinteam 4s;
}

@keyframes fadeinteam {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinteam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

@media screen and (max-width: 630px) {
  .team_h1 {
    font-size: 30px;
    margin: 40px 45px 0px 45px;
  }

  .team_h2 {
    font-size: 20px;
    margin: -10px 45px 0px 45px;
  }
}

@media screen and (max-width: 360px) {
  .team_h1 {
    font-size: 26px;
  }

  .team_h2 {
    font-size: 17px;
  }
}

@media screen and (max-height: 660px) {
  .team_h1 {
    margin: -10px 45px 0px 45px;
  }

  .team_h2 {
    margin: -15px 45px 0px 45px;
  }
}

@media screen and (min-height: 750px) {
  .team_h1 {
    top: 400px;
  }

  .team_h2 {
    top: 320px;
  }
}

@media screen and (min-height: 850px) {
  .team_h1 {
    top: 450px;
  }

  .team_h2 {
    top: 370px;
  }
}

@media screen and (min-height: 950px) {
  .team_h1 {
    top: 500px;
  }

  .team_h2 {
    top: 420px;
  }
}

@media screen and (min-height: 1050px) {
  .team_h1 {
    top: 750px;
  }

  .team_h2 {
    top: 670px;
  }
}

.intro {
  border-top: 2px solid #8f8d87;
  border-bottom: 2px solid #8f8d87;
  display: block;
  color: #4a4b4c;
  height: 100%;
  font: 19px/28px "AdobeCaslonW01-Regular", Times, serif;
}

.intro h1 {
  display: inline-block;
  vertical-align: middle;
  margin: 0 11% 0 0;
  width: 38.5%;
  text-transform: uppercase;
  font-size: 45px;
  font-family: "Helvetica";
  font-weight: 550;
  -webkit-margin-before: 0.43em;
          margin-block-start: 0.43em;
  -webkit-margin-after: 0.43em;
          margin-block-end: 0.43em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  color: #4a4b4c;
  line-height: 55px;
}

.intro .box {
  display: inline-block;
  vertical-align: middle;
  width: 61.5%;
  font-size: 26px;
  line-height: 28px;
}

.intro p {
  margin: 0;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  text-align: left;
  padding: 3% 3% 0% 15%;
  font-size: 25px;
}

@media screen and (max-width: 1000px) {
  .intro h1 {
    font-size: 40px;
  }

  .intro p {
    font-size: 22px;
    -webkit-margin-after: -5px;
            margin-block-end: -5px;
  }
}

@media screen and (max-width: 930px) {
  .intro h1 {
    font-size: 37px;
  }

  .intro p {
    font-size: 20px;
    -webkit-margin-after: -5px;
            margin-block-end: -5px;
  }
}

@media screen and (max-width: 820px) {
  .intro h1 {
    font-size: 35px;
    width: 100%;
  }

  .intro p {
    position: relative;
    font-size: 22px;
    -webkit-margin-after: 0px;
            margin-block-end: 0px;
    width: 90vw;
    padding: 0;
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 600px) {
  .intro h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .intro p {
    font-size: 18px;
    line-height: 25px;
  }
}

.main_holder_team {
  max-width: 999px;
  margin: 0 auto;
  padding: 36px 20px 47px 20px;
  display: block;
}

.team-block {
  padding: 0 0 72px;
}

.servizi_page {
  position: relative;
  width: 100%;
  height: 75%;
  min-height: 75vh;
  top: 0;
  left: 0;
  background-image: url(/static/media/Servizi.ef7869d6.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.servizi_h1 {
  position: relative;
  color: white;
  top: 345px;
  min-height: 130px;
  font-family: "Glegoo", sans-serif;
  font-size: 40px;
  font-weight: 550;
  margin: 0px 90px 0px 90px;
}

.servizi_h2 {
  position: relative;
  color: white;
  font-family: "Times New Roman";
  font-size: 30px;
  font-weight: 500;
  top: 270px;
  margin: 0px 90px 0px 90px;
  -webkit-animation: fadeinservizi 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeinservizi 4s;
}

@keyframes fadeinservizi {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinservizi {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

@media screen and (max-width: 630px) {
  .servizi_h1 {
    font-size: 30px;
    margin: 40px 45px 0px 45px;
  }

  .servizi_h2 {
    font-size: 20px;
    margin: -10px 45px 0px 45px;
  }
}

@media screen and (max-width: 360px) {
  .servizi_h1 {
    font-size: 26px;
  }

  .servizi_h2 {
    font-size: 17px;
  }
}

@media screen and (max-height: 660px) {
  .servizi_h1 {
    margin: -10px 45px 0px 45px;
  }

  .servizi_h2 {
    margin: -15px 45px 0px 45px;
  }
}

@media screen and (min-height: 750px) {
  .servizi_h1 {
    top: 400px;
  }

  .servizi_h2 {
    top: 320px;
  }
}

@media screen and (min-height: 850px) {
  .servizi_h1 {
    top: 450px;
  }

  .servizi_h2 {
    top: 370px;
  }
}

@media screen and (min-height: 950px) {
  .servizi_h1 {
    top: 500px;
  }

  .servizi_h2 {
    top: 420px;
  }
}

@media screen and (min-height: 1050px) {
  .servizi_h1 {
    top: 750px;
  }

  .servizi_h2 {
    top: 670px;
  }
}

.full_rectangle {
  height: 450px;
  width: 100%;
  margin: 0 0 30px 0;
}

.half_left {
  width: 60%;
  height: 100%;
  float: left;
  padding: 3% 3% 3% 20%;
}

@media screen and (max-width: 700px) {
  .half_left {
    width: 100%;
    padding: 3% 3% 3% 15%;
  }
}

.half_right {
  width: 40%;
  height: 100%;
  margin-left: 60%;
  padding-top: 20px;
}

.image_half {
  width: auto;
  height: 82%;
  display: block;
  margin: 0 auto 0 15%;
}

.h3_rectangle_2_4 {
  font-family: "Glegoo", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding-left: 10px;
}

.list_v ul {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAQpSURBVGhD7Zh5SBRxFMfd2103r221zLK0wiMVy3Z2jYoiCgyiogO6zISirCzU8oIgIiHooKI/6o8OCo0wzSIisrJbqcxKs/uwsssOa83Kju+bdUPHXxe57WzMFz44O++9mTfO7/fe7zdukiRJkiTpf5IeLAPj+F8upmGgVq5S3MHfB0BNJ11FqcAaMJY7ZSnK/oKHuIXfM3mLCyjTTSZ7EbFyek3c/pyvRMA4cynOH7CZxa1lSL4+cnXiLXvyxICtC57A9h7QnBCtEpH8y8hVCTdbJ29H7q66Bp+xNlfxiQONoVkTK1nJE76W0GPw2cJ7i0xdwEP/+NgTrMTt9MudUQ2/V0BUw0gGDrsHdj7LSlqIQquqgf9iPlIkSpMp5LWm/PS3rISFdJ8y9AxivoJZfLST1R9Yw1dMqWYlK2TA9pQ6ubv6KmKOAy+6gDOlAVcMQ8JLWckKiV6bdJ3eFGJ2AVF05Fwqi+bCzE+shFvTI2E4NbG3FANozvy1eoPXIBuo6MQfygQao9bMatOshFgKsz52Cut+FL5UeTq0/i9RaDVVcrXyJo4PAQ/+7O9JASqMwyKPs5K2w+1Z+k7l7VEOXyqd9A/rUJ3pNinuJLdnyXu1j/4CfpeA330Tc2VKxT2uILOJlTjB5ae/UejdL8H3FPDmozpQfuBT7PaUZ/zN8BAKnaYK5zbx1p/LAF6EJI8+L0zazsBdqfWYG3Q9GjoOaViTcIM2ZS9228LnMrn8MWwpNpcfaqPaV3+xdWxruLy0N3KNktY7NCx1fIQDtN6zX1C70heRO51uTJViDO/VXmGgKXpd0m1hLGEpzPig9NRehA8NRyqxDlNFr9kjmW0/ODm+DPZ3IJL3bKsDHr0DTrLiLMU5zdpAA433y8ChDUoJrD5cn5K44uzPrGT8RkSfgA81nK4U0KIRwBq7deFTVoyPqc8R2GnHFUjOjtYgcFdt0JdhAjPXL7oeRlqvFPPetrJZaRgUyuy4IYvGnIW9AdAQ+2ei13wU1afSlJf6WpgULcxa2v40kITjR1SthH4xm+fdxwaGmtRk8M+lBQdp4tEEFCbXN3081fF6UBc8Z1S50G4pyvzQUi43AKeJSt0lXXCXY8IECY2/dzkaXQXL1nlwOO2uzgOnL8xo4tUZh0e1ewiuIKOR9Xai1iTdQAxVqxi6gBhE+9mGkPmjf7mrMu/LbsY6isrlKj5SRJoKGqJWJ37/lsMiYIKFhs5t4LBO+zdajiVF7cAdi56zkjflpzWg6jyFn2g/jdBmYzeVV8veDKvwAXzNfWmBRo1O1KKhcU5j9CozF2V9323FbEmmj7M0cWkzI3oZQY0uyK80bl/OF3oAlNTTOLeTt7qIqLze94zuWdJrzihK/iXwJ4MribaC9FG2CThludARigDzbYeSJEmS9P/Lze0bOKXqGD+M7DwAAAAASUVORK5CYII=);
  font-size: 20px;
}

.list_v ul li {
  line-height: 30px;
  margin: 30px 0;
  padding-left: 10px;
  font-size: 22px;
}

@media screen and (max-width: 1160px) {
  .half_left {
    padding: 3% 3% 3% 13%;
  }

  .image_half {
    width: auto;
    height: 70%;
  }

  .h3_rectangle_2_4 {
    font-size: 23px;
    font-weight: 600;
  }

  .list_v ul li {
    line-height: 25px;
    margin: 25px 0;
    padding-left: 10px;
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .h3_rectangle_2_4 {
    font-size: 20px;
    margin-left: 18px;
  }

  .list_v ul li {
    font-size: 16px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 350px) {
  .h3_rectangle_2_4 {
    margin-left: 0px;
  }
}

.full_rectangle_2 {
  height: 450px;
  width: 100%;
  margin: 0 0 30px 0;
  overflow: hidden;
  background-color: #00acec;
}

.half_left_2 {
  width: 40%;
  height: 100%;
  /* margin-right: 60%; */
  float: left;
}

.half_right_2 {
  width: 60%;
  height: 100%;
  float: left;
  padding: 3% 3% 3% 7%;
  margin-top: 10px;
}

@media screen and (max-width: 700px) {
  .half_right_2 {
    width: 100%;
    padding: 3% 3% 3% 15%;
  }
}

.image_half_2 {
  width: auto;
  height: 70%;
  display: block;
  margin: 60px 15% 0 auto;
}

.h3_rectangle_2_4_2 {
  font-family: "Glegoo", sans-serif;
  color: white;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding-left: 10px;
}

.secondRectangle {
  width: 500px;
  margin: 10px;
  margin-top: 90px;
}

.ol_rectangle {
  color: white;
  list-style-type: none;
}

.li_rectangle {
  position: relative;
  font: bold italic 50px/1.5 Helvetica, Verdana, sans-serif;
  margin-bottom: 20px;
  vertical-align: top;
  margin: 90px 0;
}

.p_rectangle {
  line-height: 30px;
  margin: -50px 0 0 0;
  padding-left: 50px;
  font-size: 22px;
  color: white;
  width: 600px;
}

.number_rectangle {
  position: absolute;
  margin-top: -15px;
}

@media screen and (max-width: 1160px) {
  .half_right_2 {
    padding: 3% 3% 3% 5%;
    margin-top: 40px;
  }

  .image_half_2 {
    width: auto;
    height: 60%;
    margin-top: 90px;
  }

  .h3_rectangle_2_4_2 {
    font-size: 23px;
    font-weight: 600;
  }

  .li_rectangle {
    font-size: 45px;
  }

  .p_rectangle {
    font-size: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .half_right_2 {
    padding: 3% 3% 3% 0%;
  }
  .li_rectangle {
    font-size: 40px;
  }

  .p_rectangle {
    font-size: 17px;
    padding-right: 90px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 700px) {
  .half_right_2 {
    padding: 3% 3% 3% 10%;
  }

  .h3_rectangle_2_4_2 {
    font-size: 20px;
    margin-left: 18px;
    padding-left: 10px;
  }

  .p_rectangle {
    padding-right: 0%;
    margin-top: -50px;
  }
}

@media screen and (max-width: 600px) {
  .p_rectangle {
    padding-right: 20%;
  }
}

@media screen and (max-width: 470px) {
  .p_rectangle {
    padding-right: 40%;
  }
}

@media screen and (max-width: 380px) {
  .p_rectangle {
    padding-right: 50%;
  }

  .li_rectangle {
    margin: 70px 0;
  }
}

@media screen and (max-width: 350px) {
  .h3_rectangle_2_4_2 {
    margin-left: 0px;
  }
}

.full_rectangle {
  height: 450px;
  width: 100%;
  margin: 0 0 30px 0;
}

.half_left {
  width: 60%;
  height: 100%;
  float: left;
  padding: 3% 3% 3% 20%;
}

@media screen and (max-width: 700px) {
  .half_left {
    width: 100%;
    padding: 3% 3% 3% 15%;
  }
}

.half_right_3 {
  width: 40%;
  height: 100%;
  margin-left: 60%;
}

.image_half_3 {
  width: auto;
  height: 90%;
  display: block;
  margin: 0 auto 0 10%;
  padding-top: 8%;
}

.h3_rectangle_2_4 {
  font-family: "Glegoo", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding-left: 10px;
}

.list_v #second_ul {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAATVSURBVGhD7VlLbxNXFE5LWwRUlVoeFRAS24lYhCXiUViwK/yAIiS6a6HQX0ALrdJNAQmxMPGMCQ/BqggQSKhqBT+jQFctSEB5pAQU3xsj0gXT7xuf4Lnje+2Z8Rg2/qQj23Pec8899+GBPvroIwdcChYM+7XNRV8dLPj6StHTf4KeFzz1Hyn87qvbIQ8ylB0YD94V7beHEe/FmqKvjyKoh/gMUpGn/sHn0dFyfVDMvTmsnVTL4PwU3u6cEVQGEhtV2hTzvQWc7UZJPIsGkQcVPP0Un7vFTf4YuxR8ACen447nCTyFsriActpT9OobRstq+frJ4H0Sv/NZoaL2UgZzQdtshOTpk9QRt/lg5WSwGI5/tztUjxD0t4PHXy0S8Y4I7SFRJPKXzSbK6jfKiHh34Ju3BY+u8hL0w1hl6kMRTY3GqKoDtBW3j5G4lstI2MtGPR4+WftMRLrGiFfbgkSetPhBOYlINhSr+su4UfZztk8RyQ20Sdst/jy9S0TSIZx4vp42jaknvezbtI158a/pUz9dNVFbKiLJweEzDam5QqW2Sdg9g5STsb7gd0XYyRAOZ9yIr34UtokgeAdv7fCQP1OSJ12j5KvvDN+IZbBaXy3szoAStgdNA2yVnx57vETYTTB4meRI8H5eSTQ6n74TjQF+jgi7A7DJQsDcp7xWHvbUfuE2EQl+nvJMAra+idtOtAFkezQUscJaFxXsQME/F5UN5XNKgiPesmJXZzYK2w30+INRJQT0i7BakTUJbr8rszvklxOohItRu5wbwnIDtXc1qsQlX1h2pE2C8p46D7s35IkTharaZ9rUl4XlBhybi0mSYUuaxOvgwcehR5460TgkRex56paw3IDh51GlxItIpySiwYfPdU00nZBzR9TetLDciPd/tjRhdUabJLAI4jhpPh8tT38kmlaMll8tjMozNmG50VUChCMJGw1V1ZhoWZEpgXgJZTrqJUyi4M1+LhpWZC2hW4ZSkklsA1dTx4GlSbWvRNqKljXJVzeF5QYml1GrPAYKKzliE9ZFCGhcNKzI1Ebh+PuoEn5fEFYyJAyehFX+rGhZATvGQobqOCAsN1p7r1bWjZwNKYJvkHsxo0/IzBryXn2DsNsAGyYM7QNDsdNqLOD2AAlc5yLFPm/asFCbxaylfDx1L/FtHreuprL+O8sBm31+pKLXFfzZ7UjsawT8ExI700xSPRBRA9I+7xox4Mwh7M4Ij3ax9SBR/eUEy4by5ZqJ+iphJwMUq4YRJJTnTYQLparaanl5E8JODu6BoGwe6nHg7uWhvnEzETvU4/fg6ZlPRCQdUPu7osYaBtXt0uSLIRHJDbRJ23F/oJ0ikg2YbMbtBAlDOsWhFpGuIWUzFfeDyV4WkezYNh68hySuxY1zYvGElHqzF0Hj8K4O0VbcPn1yXRHR7sAzMcrp1xYnDbrLnp3mjpSy0ueNVjlP9JXb5e48wpGIdaYoYbJpvM2LDIwXYCVfr+AbJvE7n4VBU6bd9bqvTuT25m2Ak514Q/wzwuI8O4Xdp6K+EDe9Bdsae7O1dlMSbdAWjp0fi/k3B1734c0dRgD3bMG1I+pgJH9OdWXYM/A2DwcfdiUkdBlv9Sa6yDMEOUcKv/vqj5CHLUm4q0y6Meujjz7aYGDgf8w8PHLdwyY5AAAAAElFTkSuQmCC);
  font-size: 20px;
}

.list_v ul li {
  line-height: 30px;
  margin: 30px 0;
  padding-left: 10px;
  font-size: 22px;
}

@media screen and (max-width: 1160px) {
  .half_left {
    padding: 3% 3% 3% 13%;
  }

  .image_half_3 {
    width: auto;
    height: 80%;
    padding-top: 12%;
  }

  .h3_rectangle_2_4 {
    font-size: 23px;
    font-weight: 600;
  }

  .list_v ul li {
    line-height: 25px;
    margin: 25px 0;
    padding-left: 10px;
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .image_half_3 {
    padding-left: 0%;
    margin: 0;
  }
}

@media screen and (max-width: 780px) {
  .image_half_3 {
    height: 76%;
    padding-top: 20%;
  }
}

@media screen and (max-width: 630px) {
  .h3_rectangle_2_4 {
    font-size: 20px;
    margin-left: 18px;
  }

  .list_v ul li {
    font-size: 16px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 350px) {
  .h3_rectangle_2_4 {
    margin-left: 0px;
  }
}

.title_servizi {
  width: 500px;
  height: 50px;
  margin: 80px auto 50px auto;
  text-align: center;
  font-family: "Glegoo", sans-serif;
  font-size: 35px;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .title_servizi {
    width: 350px;
    font-size: 25px;
  }
}

@media screen and (max-width: 630px) {
  .title_servizi {
    width: 300px;

    font-size: 20px;
  }
}

.perche_noi_page {
  position: relative;
  width: 100%;
  height: 75%;
  min-height: 75vh;
  top: 0;
  left: 0;
  background-image: url(/static/media/Perche_noi.f20cd8b5.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.perche_noi_h1 {
  position: relative;
  color: white;
  top: 345px;
  min-height: 130px;
  font-family: "Glegoo", sans-serif;
  font-size: 40px;
  font-weight: 550;
  margin: 0px 90px 0px 90px;
}

.perche_noi_h2 {
  position: relative;
  color: white;
  font-family: "Times New Roman";
  font-size: 30px;
  font-weight: 500;
  top: 270px;
  margin: 0px 90px 0px 90px;
  -webkit-animation: fadeinperche_noi 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeinperche_noi 4s;
}

@keyframes fadeinperche_noi {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinperche_noi {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

@media screen and (max-width: 630px) {
  .perche_noi_h1 {
    font-size: 30px;
    margin: 40px 45px 0px 45px;
  }

  .perche_noi_h2 {
    font-size: 20px;
    margin: -10px 45px 0px 45px;
  }
}

@media screen and (max-width: 360px) {
  .perche_noi_h1 {
    font-size: 26px;
  }

  .perche_noi_h2 {
    font-size: 17px;
  }
}

@media screen and (max-height: 660px) {
  .perche_noi_h1 {
    margin: -10px 45px 0px 45px;
  }

  .perche_noi_h2 {
    margin: -15px 45px 0px 45px;
  }
}

@media screen and (min-height: 750px) {
  .perche_noi_h1 {
    top: 400px;
  }

  .perche_noi_h2 {
    top: 320px;
  }
}

@media screen and (min-height: 850px) {
  .perche_noi_h1 {
    top: 450px;
  }

  .perche_noi_h2 {
    top: 370px;
  }
}

@media screen and (min-height: 950px) {
  .perche_noi_h1 {
    top: 500px;
  }

  .perche_noi_h2 {
    top: 420px;
  }
}

@media screen and (min-height: 1050px) {
  .perche_noi_h1 {
    top: 750px;
  }

  .perche_noi_h2 {
    top: 670px;
  }
}

.title_perche_noi {
  width: 500px;
  height: 50px;
  margin: 80px auto 50px auto;
  text-align: center;
  font-family: "Glegoo", sans-serif;
  font-size: 35px;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .title_perche_noi {
    width: 350px;
    font-size: 25px;
  }
}

@media screen and (max-width: 630px) {
  .title_perche_noi {
    width: 300px;

    font-size: 20px;
  }
}

.my_video {
  display: table;
  margin: 0 auto;
}

.my_video_inside {
  width: 640px;
  height: 400px;
}

@media screen and (max-width: 900px) {
  .my_video_inside {
    width: 540px;
    height: 338px;
  }
}

@media screen and (max-width: 630px) {
  .my_video_inside {
    width: 400px;
    height: 250px;
  }
}

@media screen and (max-width: 450px) {
  .my_video_inside {
    width: 300px;
    height: 188px;
  }
}

.news_page {
  position: relative;
  width: 100%;
  height: 75%;
  min-height: 75vh;
  top: 0;
  left: 0;
  background-image: url(/static/media/news.1341c5e5.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.news_h1 {
  position: relative;
  color: white;
  top: 345px;
  min-height: 130px;
  font-family: "Glegoo", sans-serif;
  font-size: 40px;
  font-weight: 550;
  margin: 0px 90px 0px 90px;
}

.news_h2 {
  position: relative;
  color: white;
  font-family: "Times New Roman";
  font-size: 30px;
  font-weight: 500;
  top: 270px;
  margin: 0px 90px 0px 90px;
  -webkit-animation: fadeinnews 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeinnews 4s;
}

@keyframes fadeinnews {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinnews {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

@media screen and (max-width: 630px) {
  .news_h1 {
    font-size: 30px;
    margin: 40px 45px 0px 45px;
  }

  .news_h2 {
    font-size: 20px;
    margin: -10px 45px 0px 45px;
  }
}

@media screen and (max-width: 360px) {
  .news_h1 {
    font-size: 26px;
  }

  .news_h2 {
    font-size: 17px;
  }
}

@media screen and (max-height: 660px) {
  .news_h1 {
    margin: -10px 45px 0px 45px;
  }

  .news_h2 {
    margin: -15px 45px 0px 45px;
  }
}

@media screen and (min-height: 750px) {
  .news_h1 {
    top: 400px;
  }

  .news_h2 {
    top: 320px;
  }
}

@media screen and (min-height: 850px) {
  .news_h1 {
    top: 450px;
  }

  .news_h2 {
    top: 370px;
  }
}

@media screen and (min-height: 950px) {
  .news_h1 {
    top: 500px;
  }

  .news_h2 {
    top: 420px;
  }
}

@media screen and (min-height: 1050px) {
  .news_h1 {
    top: 750px;
  }

  .news_h2 {
    top: 670px;
  }
}


.title_news {
  width: 500px;
  height: 50px;
  margin: 80px auto 50px auto;
  text-align: center;
  font-family: "Glegoo", sans-serif;
  font-size: 35px;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .title_news {
    width: 350px;
    font-size: 25px;
  }
}

@media screen and (max-width: 630px) {
  .title_news {
    width: 300px;
  }
}

.manage_button1 {
  margin: 0 100px 0 20px;
  height: 60px;
}

.manage_button2 {
  height: 60px;
}

@media screen and (max-width: 1000px) {
  .manage_button1 {
    margin: 0 50px 0 5px;
  }
}

@media screen and (max-width: 790px) {
  .manage_button1 {
    margin: 0 0px 20px 0px;
  }
}

@media screen and (max-width: 790px) {
  .manage_button1 {
    margin: 0 20px 0px 0px;
  }
}

@media screen and (max-width: 766px) {
  .manage_button1 {
    margin: 0 0 40px 0;

    height: 80px;
  }

  .manage_button2 {
    height: 80px;
    margin-bottom: 0px;
  }
}

.manage_button3 {
  height: 60px;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 766px) {
  .manage_button3 {
    margin: -5px 0 40px 0;
    height: 80px;
  }
}

.full-width-div {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background: url(/static/media/Contatti.f9e336a6.jpg) no-repeat center top;
}

.center-contatti {
  position: relative;
  -webkit-transform: translate(0px, 25%);
          transform: translate(0px, 25%);
  top: 50%;
}

@media screen and (min-height: 1080px) {
  .full-width-div {
    background: url(/static/media/Contatti.f9e336a6.jpg) no-repeat center top;
  }
}

@media screen and (max-height: 680px) {
  .center-contatti {
    -webkit-transform: translate(0px, 15%);
            transform: translate(0px, 15%);
  }
}

@media screen and (max-height: 580px) {
  .center-contatti {
    -webkit-transform: translate(0px, 10%);
            transform: translate(0px, 10%);
  }
}

.btn.btn-light.btn-block.button-contatti {
  background: #00acec;
  color: white;
  border-radius: 5px;
  width: 20%;
  margin: 0 auto;
}

@media screen and (max-width: 650px) {
  .btn.btn-light.btn-block.button-contatti {
    width: 30%;
  }
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Montserrat-Regular;
  src: url(/static/media/Montserrat-Regular.9c460951.ttf);
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url(/static/media/Montserrat-ExtraBold.9bc77c3b.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(/static/media/Montserrat-Bold.88932dad.ttf);
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/
a .login {
  font-family: Montserrat-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus .login {
  outline: none !important;
}

a:hover .login {
  text-decoration: none;
  color: #fff;
}

/*---------------------------------------------*/

.input100 {
  outline: none;
  border: none;
}

.input100:focus {
  border-color: transparent !important;
}

.input100:focus::-webkit-input-placeholder {
  color: transparent;
}
.input100:focus:-moz-placeholder {
  color: transparent;
}
.input100:focus::-moz-placeholder {
  color: transparent;
}
.input100:focus:-ms-input-placeholder {
  color: transparent;
}

.input100::-webkit-input-placeholder {
  color: #999999;
}
.input100:-moz-placeholder {
  color: #999999;
}
.input100::-moz-placeholder {
  color: #999999;
}
.input100:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
.txt1 {
  font-family: Montserrat-Regular;
  font-size: 16px;
  color: #cccccc;
  line-height: 1.4;
}

.bo1 {
  border-bottom: 1px solid #999999;
}

.hov1:hover {
  border-color: #d33f8d;
}

/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  z-index: 1;
  background-image: url(/static/media/login_background.92fcb8ed.jpg);
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #005bea;
  background-image: linear-gradient(180deg, #005bea 0%, #00c6fb 100%);
  background: linear-gradient(bottom, #005bea, #00c6fb);
  opacity: 0.7;
}

.wrap-login100 {
  width: 390px;
  background: transparent;
}

/*------------------------------------------------------------------
  [  ]*/
.login100-form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.login100-form-title {
  font-family: Montserrat-ExtraBold;
  font-size: 24px;
  color: #fff;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  margin-bottom: 30px;
}

/*---------------------------------------------*/
.login100-form-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.login100-form-avatar img {
  width: 100%;
}

/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
}

.input100 {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.2;
  color: #333333;

  display: block;
  width: 100%;
  background: #fff;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 53px;
  margin: 3px auto;
}

/*------------------------------------------------------------------
  [ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(0, 91, 234, 0.6);
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;
  color: #999999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  pointer-events: none;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #00c6fb;
  padding-left: 23px;
}

/*------------------------------------------------------------------
  [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.login100-form-btn {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #e0e0e0;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #333333;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 25px;
  transition: all 0.4s;

  position: relative;
  z-index: 1;
  margin: 10px auto;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  top: 0;
  left: 0;
  background: #005bea;
  background: linear-gradient(left, #005bea, #00c6fb);
  transition: all 0.4s;
  opacity: 0;
}

.login100-form-btn:hover {
  background: transparent;
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 1;
}

/*------------------------------------------------------------------
  [ Button sign in with ]*/
.btn-face,
.btn-google {
  font-family: Raleway-Bold;
  font-size: 16px;
  line-height: 1.2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: calc((100% - 10px) / 2);
  height: 40px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  transition: all 0.4s;
}

.btn-face {
  color: #3b5998;
}

.btn-face i {
  font-size: 20px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.btn-google {
  color: #555555;
}

.btn-google img {
  width: 19px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.btn-face:hover,
.btn-google:hover {
  border-color: #d33f8d;
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 14px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: Montserrat-Bold;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\F06A";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*//////////////////////////////////////////////////////////////////
  [ responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
}


html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

@media screen and (max-width: 669px) {
  .bm-burger-button {
    width: 25px;
    height: 20px;
    left: 25px;
    top: 25px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #d9d9d9;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/*
 * Navbar
 */

.navbar .navbar-expand-lg {
  border: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  padding: 10px 60px;
}

@media screen and (max-width: 500px) {
  .navbar .navbar-expand-lg {
    padding: 10px 30px;
  }
}

.navbar .navbar-brand {
  color: black;
  font-size: 18px;
  font-weight: bold;
  /* margin-left: 50px; */
}

/*
   * Header
   */

.header {
  border: 0;
  border-bottom: solid #1367e4;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.header .name {
  font-size: 30px;
  font-weight: bold;
  margin-top: 35px;
}

.header .description {
  font-size: 20px;
  margin-top: 3px;
}

.header .links {
  margin-top: 25px;
}

.header .links a {
  color: #1367e4;
  font-size: 20px;
  font-weight: lighter;
  margin: 0 10px;
}

/*
   * Content
   */

.content {
  margin-top: 30px;
}

.content h1 {
  font-size: 20px;
  font-weight: bold;
}

.content p {
  font-size: 18px;
  line-height: 1.95;
  text-align: justify;
}

.logo_footer {
  width: 40px;
  height: 40px;
}

.p_footer {
  margin: 5px 0 5px 0;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .p_footer {
    margin: 20px 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .p_footer {
    margin: 20px 0 0 0;
  }
}

.darkLabel label {
  color: black !important;
}

