.title_news {
  width: 500px;
  height: 50px;
  margin: 80px auto 50px auto;
  text-align: center;
  font-family: "Glegoo", sans-serif;
  font-size: 35px;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .title_news {
    width: 350px;
    font-size: 25px;
  }
}

@media screen and (max-width: 630px) {
  .title_news {
    width: 300px;
  }
}
