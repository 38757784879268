/*
 * Navbar
 */

.navbar .navbar-expand-lg {
  border: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  padding: 10px 60px;
}

@media screen and (max-width: 500px) {
  .navbar .navbar-expand-lg {
    padding: 10px 30px;
  }
}

.navbar .navbar-brand {
  color: black;
  font-size: 18px;
  font-weight: bold;
  /* margin-left: 50px; */
}

/*
   * Header
   */

.header {
  border: 0;
  border-bottom: solid #1367e4;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.header .name {
  font-size: 30px;
  font-weight: bold;
  margin-top: 35px;
}

.header .description {
  font-size: 20px;
  margin-top: 3px;
}

.header .links {
  margin-top: 25px;
}

.header .links a {
  color: #1367e4;
  font-size: 20px;
  font-weight: lighter;
  margin: 0 10px;
}

/*
   * Content
   */

.content {
  margin-top: 30px;
}

.content h1 {
  font-size: 20px;
  font-weight: bold;
}

.content p {
  font-size: 18px;
  line-height: 1.95;
  text-align: justify;
}
