.responsive {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background: url("../../photos/homepage_photo.jpg") no-repeat center top;
}

.main_logo {
  -webkit-animation: fadein 6s;
  -moz-animation: fadein 6s;
  -ms-animation: fadein 6s;
  -o-animation: fadein 6s;
  animation: fadein 6s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main_phrase {
  color: white;
  -webkit-animation: fadeinT 2s;
  -moz-animation: fadeinT 2s;
  -ms-animation: fadeinT 2s;
  -o-animation: fadeinT 2s;
  animation: fadeinT 2s;
}

@media screen and (max-height: 690px) {
  .main_phrase {
    color: #ffffff;
    opacity: 0;
    -webkit-animation: fadeinT 0s;
    -moz-animation: fadeinT 0s;
    -ms-animation: fadeinT 0s;
    -o-animation: fadeinT 0s;
    animation: fadeinT 0s;
  }
}

@keyframes fadeinT {
  0% {
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    transform: translateX(-0px);
    opacity: 1;
  }
}

@-moz-keyframes fadeinT {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeinT {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadeinT {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeinT {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300);
* {
  margin: 0;
  padding: 0;
}
/* html {
  scroll-behavior: smooth;
} */
html,
body {
  height: 100%;
}
section {
  position: relative;
  width: 100%;
  height: 100%;
}
section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 80%;
}
section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font: normal 300 64px/1 "Josefin Sans", sans-serif;
  text-align: center;
  white-space: nowrap;
}

.scroll_homepage a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}
.scroll_homepage a:hover {
  opacity: 0.5;
}

#section05 a {
  padding-top: 70px;
}
#section05 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

#main {
  padding: 50px 50px 50px 50px !important;
  position: relative;
  z-index: 5;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  display: block;
}

.box_left {
  width: 40%;
  margin-left: 40px;
  display: inline-block;
  box-sizing: border-box;
}

.box_left {
  height: 300px;
  background: #00acec;
  padding: 40px;
  z-index: 10;
  position: relative;
}

.box_left h2 {
  color: #fff;
  line-height: 1.1em;
  font-size: 46px;
  font-family: "Glegoo", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.next_to_box {
  padding-top: 40px;
  padding-left: 5%;
  display: inline-block;
  width: 55%;
  vertical-align: top;
  box-sizing: border-box;
}

.next_to_box h4 {
  font-size: 26px;
  line-height: 33.8px;
  clear: both;
  font-weight: 100;
  color: #404040;
  margin: 0 0 26px 0;
  font-family: "Times New Roman";
}

.image_business {
  width: 100%;
  height: 300px;
  background: url("../../photos/foto_1.jpg");
  background-size: cover;
  background-position: 50% 50%;
  margin-top: -100px;
  position: relative;
  z-index: -1;
}

#content_3:before {
  content: "Al centro delle tue esigenze";
  font-family: "Times New Roman";
  font-weight: 700;
  font-size: 32px;
}

@media screen and (max-width: 1190px) {
  .box_left {
    width: 100%;
    height: auto;
    margin-left: 0;
    background: none;
    padding: 0;
    display: inline-block;
    box-sizing: border-box;
    z-index: 10;
    position: relative;
    text-align: center;
  }

  .box_left h2 {
    margin: 0 auto 0 auto;
    color: #00acec;
    padding: 0;
    max-width: 500px;
    font-size: 54px;
    line-height: 1em;
    font-family: "Glegoo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    clear: both;
  }

  .next_to_box {
    width: 100%;
    padding: 0;
    vertical-align: top;
    box-sizing: border-box;
    text-align: center;
  }

  .next_to_box h4 {
    font-size: 24px;
    padding: 0 10%;
    line-height: 1.3em;
    margin: 30px 0 30px 0;
  }

  .image_business {
    margin: 0 0 0 0;
    height: 270px;
  }

  #content_3:before {
    content: none;
  }
}

@media screen and (max-width: 630px) {
  .box_left h2 {
    margin: 0px auto 0 auto;
    color: #00acec;
    padding: 0;
    max-width: 500px;
    font-size: 35px;
    line-height: 1em;
    font-family: "Glegoo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    clear: both;
  }

  .next_to_box h4 {
    font-size: 20px;
  }

  .half h5 {
    font-size: 21px !important;
  }

  #main {
    padding: 50px 40px 50px 40px !important;
  }

  .text_h2 {
    margin-top: 80px;
  }
}

.services_container {
  overflow: hidden;
  padding-top: 40px;
  margin-top: -40px;
}

.half {
  width: 50%;
  float: left;
  padding: 0 20px 0 80px;
  box-sizing: border-box;
  margin-top: -20px;
}

.half h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.1em;
  font-size: 40px;
  font-family: "Glegoo", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.half h5 {
  font-size: 25px;
  line-height: 28px;
  clear: both;
  font-weight: 300;
  color: #404040;
  margin: 0 0 26px 0;
  font-family: "Times New Roman";
}

.half p {
  font-size: 20px;
  line-height: 25px;
  clear: both;
  font-weight: 100;
  color: #404040;
  margin: 0 0 26px 0;
  font-family: "Times New Roman";
}

@media screen and (max-width: 900px) {
  .half {
    width: 100%;
    float: bottom;
    padding: 0 0px 0 0px;
    margin: 0 0 0 0;
  }

  .icon_circle {
    opacity: 0;
  }

  .icon_circle i {
    display: none;
  }

  .elegant_icon {
    display: none;
  }

  .half h2 {
    font-size: 35px;
    text-align: center;
  }

  .half h5 {
    text-align: center;
  }

  .half p {
    text-align: center;
  }
}

@media screen and (max-width: 335px) {
  .box_left h2 {
    font-size: 25px;
  }

  .half h2 {
    font-size: 30px;
    text-align: center;
  }
}

.icon_circle {
  background: #00acec;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  top: -10px;
  left: -40px;
  text-align: center;
  display: table;
  box-shadow: 0 0 0 2px #fff, 0 0 0 7px #00acec;
}

.icon_circle i {
  color: #fff;
  font-size: 35px;
  display: table-cell;
  vertical-align: middle;
}

.elegant_icon {
  font-family: "ElegantIcons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

#content_1:before {
  content: "G&F CONSULTING STP SRL nasce nel 2018 dall'esperienza pluridecennale dei suoi collaboratori e vanta ad oggi più di 80 aziende in tutta Italia. La lunga esperienza maturata garantisce ai nostri clienti una competenza e conoscenza approfondita della materia.";
}

#content_2:before {
  content: "Perchè scegliere noi? Ci impegniamo ogni giorno per ascoltare le vere esigenze dei nostri clienti e trovare una risposta efficace ai loro problemi. Da sempre investiamo nell'innovazione per offrirti una qualità elevata e velocità nelle risposte.";
}

@media screen and (max-width: 630px) {
  #content_1:before {
    content: none;
  }

  #content_2:before {
    content: none;
  }

  .home_testimonial {
    padding: 15px 15px 15px 15px !important;
    margin: 50px 10px 50px 10px !important;
  }

  .home_testimonial h4 {
    font-size: 20px;
  }
}

.home_testimonial {
  background: #eee;
  border-radius: 30px;
  text-align: center;
  margin: 50px 40px 76px 40px;
  padding: 30px 60px;
  border: 2px solid #eee;
  border-left: 0;
  border-right: 0;
  position: relative;
  display: block;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

#myPageContent,
section {
  height: 100% !important;
  width: 100% !important;
}

#textSlider.row {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
}
#textSlider div {
  color: black;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}

.iamCol {
  text-align: right;
  float: left;
  font-weight: 300;
  font-size: 30pt;
}
.iamCol p:nth-child(2) {
  margin-top: -20pt !important;
}
.slideCol {
  text-align: left;
  overflow: hidden;
  font-weight: 900;
  font-size: 70pt;
  display: block;
  white-space: nowrap;
}
.slideCol p {
  margin: 0px !important;
}

.scroller {
  height: 70pt;
  line-height: 70pt;
  overflow: hidden;
}
.scroller .inner {
  animation: 10s normal infinite running scroll;
}
@keyframes scroll {
  0% {
    margin-top: 0px;
  }
  15% {
    margin-top: 0px;
  }

  25% {
    margin-top: -70pt;
  }
  40% {
    margin-top: -70pt;
  }

  50% {
    margin-top: -140pt;
  }
  65% {
    margin-top: -140pt;
  }

  75% {
    margin-top: -210pt;
  }
  90% {
    margin-top: -210pt;
  }

  100% {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .iamCol {
    text-align: center;
    font-size: 20pt;
  }
  .iamCol p {
    display: inline !important;
  }
  .slideCol {
    font-size: 25pt;
    text-align: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 765px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .iamCol {
    text-align: center;
    font-size: 25pt;
  }
  .iamCol p {
    display: inline !important;
  }
  .slideCol {
    font-size: 38pt;
    text-align: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1192px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .iamCol {
    text-align: right;
    font-size: 30pt;
  }
  .slideCol {
    font-size: 55pt;
    text-align: left;
  }
}

.image_business2 {
  width: 100%;
  height: 300px;
  background: url("../../photos/foto_4.jpg");
  background-size: cover;
  background-position: 50% 50%;
  margin-top: -50px;
  position: relative;
  z-index: -1;
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  margin: 70px auto;
}

.button2 {
  background-color: white;
  color: #008cba;
  border: 2px solid #008cba;
}

.button2:hover {
  background-color: #008cba;
  color: white;
}

.centerButton {
  margin: auto auto;
  width: 160px;
}

.homepageh2 {
  margin-top: -10px;
}
