.full_rectangle_2 {
  height: 450px;
  width: 100%;
  margin: 0 0 30px 0;
  overflow: hidden;
  background-color: #00acec;
}

.half_left_2 {
  width: 40%;
  height: 100%;
  /* margin-right: 60%; */
  float: left;
}

.half_right_2 {
  width: 60%;
  height: 100%;
  float: left;
  padding: 3% 3% 3% 7%;
  margin-top: 10px;
}

@media screen and (max-width: 700px) {
  .half_right_2 {
    width: 100%;
    padding: 3% 3% 3% 15%;
  }
}

.image_half_2 {
  width: auto;
  height: 70%;
  display: block;
  margin: 60px 15% 0 auto;
}

.h3_rectangle_2_4_2 {
  font-family: "Glegoo", sans-serif;
  color: white;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding-left: 10px;
}

.secondRectangle {
  width: 500px;
  margin: 10px;
  margin-top: 90px;
}

.ol_rectangle {
  color: white;
  list-style-type: none;
}

.li_rectangle {
  position: relative;
  font: bold italic 50px/1.5 Helvetica, Verdana, sans-serif;
  margin-bottom: 20px;
  vertical-align: top;
  margin: 90px 0;
}

.p_rectangle {
  line-height: 30px;
  margin: -50px 0 0 0;
  padding-left: 50px;
  font-size: 22px;
  color: white;
  width: 600px;
}

.number_rectangle {
  position: absolute;
  margin-top: -15px;
}

@media screen and (max-width: 1160px) {
  .half_right_2 {
    padding: 3% 3% 3% 5%;
    margin-top: 40px;
  }

  .image_half_2 {
    width: auto;
    height: 60%;
    margin-top: 90px;
  }

  .h3_rectangle_2_4_2 {
    font-size: 23px;
    font-weight: 600;
  }

  .li_rectangle {
    font-size: 45px;
  }

  .p_rectangle {
    font-size: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .half_right_2 {
    padding: 3% 3% 3% 0%;
  }
  .li_rectangle {
    font-size: 40px;
  }

  .p_rectangle {
    font-size: 17px;
    padding-right: 90px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 700px) {
  .half_right_2 {
    padding: 3% 3% 3% 10%;
  }

  .h3_rectangle_2_4_2 {
    font-size: 20px;
    margin-left: 18px;
    padding-left: 10px;
  }

  .p_rectangle {
    padding-right: 0%;
    margin-top: -50px;
  }
}

@media screen and (max-width: 600px) {
  .p_rectangle {
    padding-right: 20%;
  }
}

@media screen and (max-width: 470px) {
  .p_rectangle {
    padding-right: 40%;
  }
}

@media screen and (max-width: 380px) {
  .p_rectangle {
    padding-right: 50%;
  }

  .li_rectangle {
    margin: 70px 0;
  }
}

@media screen and (max-width: 350px) {
  .h3_rectangle_2_4_2 {
    margin-left: 0px;
  }
}
