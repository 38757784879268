.team_page {
  position: relative;
  width: 100%;
  height: 75%;
  min-height: 75vh;
  top: 0;
  left: 0;
  background-image: url("../../../photos/Team.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.team_h1 {
  position: relative;
  color: white;
  top: 345px;
  min-height: 130px;
  font-family: "Glegoo", sans-serif;
  font-size: 40px;
  font-weight: 550;
  margin: 0px 90px 0px 90px;
}

.team_h2 {
  position: relative;
  color: white;
  font-family: "Times New Roman";
  font-size: 30px;
  font-weight: 500;
  top: 270px;
  margin: 0px 90px 0px 90px;
  -webkit-animation: fadeinteam 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinteam 4s; /* Firefox < 16 */
  -ms-animation: fadeinteam 4s; /* Internet Explorer */
  -o-animation: fadeinteam 4s; /* Opera < 12.1 */
  animation: fadeinteam 4s;
}

@keyframes fadeinteam {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinteam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinteam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinteam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinteam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 630px) {
  .team_h1 {
    font-size: 30px;
    margin: 40px 45px 0px 45px;
  }

  .team_h2 {
    font-size: 20px;
    margin: -10px 45px 0px 45px;
  }
}

@media screen and (max-width: 360px) {
  .team_h1 {
    font-size: 26px;
  }

  .team_h2 {
    font-size: 17px;
  }
}

@media screen and (max-height: 660px) {
  .team_h1 {
    margin: -10px 45px 0px 45px;
  }

  .team_h2 {
    margin: -15px 45px 0px 45px;
  }
}

@media screen and (min-height: 750px) {
  .team_h1 {
    top: 400px;
  }

  .team_h2 {
    top: 320px;
  }
}

@media screen and (min-height: 850px) {
  .team_h1 {
    top: 450px;
  }

  .team_h2 {
    top: 370px;
  }
}

@media screen and (min-height: 950px) {
  .team_h1 {
    top: 500px;
  }

  .team_h2 {
    top: 420px;
  }
}

@media screen and (min-height: 1050px) {
  .team_h1 {
    top: 750px;
  }

  .team_h2 {
    top: 670px;
  }
}
