.full-width-div {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background: url("../../photos/Contatti.jpg") no-repeat center top;
}

.center-contatti {
  position: relative;
  transform: translate(0px, 25%);
  top: 50%;
}

@media screen and (min-height: 1080px) {
  .full-width-div {
    background: url("../../photos/Contatti.jpg") no-repeat center top;
  }
}

@media screen and (max-height: 680px) {
  .center-contatti {
    transform: translate(0px, 15%);
  }
}

@media screen and (max-height: 580px) {
  .center-contatti {
    transform: translate(0px, 10%);
  }
}

.btn.btn-light.btn-block.button-contatti {
  background: #00acec;
  color: white;
  border-radius: 5px;
  width: 20%;
  margin: 0 auto;
}

@media screen and (max-width: 650px) {
  .btn.btn-light.btn-block.button-contatti {
    width: 30%;
  }
}
