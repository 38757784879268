.manage_button1 {
  margin: 0 100px 0 20px;
  height: 60px;
}

.manage_button2 {
  height: 60px;
}

@media screen and (max-width: 1000px) {
  .manage_button1 {
    margin: 0 50px 0 5px;
  }
}

@media screen and (max-width: 790px) {
  .manage_button1 {
    margin: 0 0px 20px 0px;
  }
}

@media screen and (max-width: 790px) {
  .manage_button1 {
    margin: 0 20px 0px 0px;
  }
}

@media screen and (max-width: 766px) {
  .manage_button1 {
    margin: 0 0 40px 0;

    height: 80px;
  }

  .manage_button2 {
    height: 80px;
    margin-bottom: 0px;
  }
}

.manage_button3 {
  height: 60px;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 766px) {
  .manage_button3 {
    margin: -5px 0 40px 0;
    height: 80px;
  }
}
